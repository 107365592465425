var user = localStorage.getItem("user");
user = JSON.parse(user);
var arr = [
  {
    title: 'الرئيسية',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'التحكم في المستخدمين',
    route: 'users',
    icon: 'UsersIcon',
  },
  {
    title: 'التحكم في الطلبات',
    route: 'orders',
    icon: 'ShoppingCartIcon',
  },
], allowed = []

var t = false;

arr.forEach((r) => {
  allowed.push(r)
})

export default allowed
